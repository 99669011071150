import React from 'react'

import ImageGallery from 'react-image-gallery';

import './slideshow.scss';
 
const images = [
    {
        original: './assets/zeleni balkon1.jpg',
        thumbnail: './assets/zeleni balkon1.jpg',
    },
    {
        original: './assets/zeleni slika.jpg',
        thumbnail: './assets/zeleni slika.jpg',
    },
    {
        original: './assets/zeleni sve.jpg',
        thumbnail: './assets/zeleni sve.jpg',
    },
    {
        original: './assets/zeleni tv kamen.jpg',
        thumbnail: './assets/zeleni tv kamen.jpg',
    },
    {
        original: './assets/zeleni ulaz.jpg',
        thumbnail: './assets/zeleni ulaz.jpg',
    },
    {
        original: './assets/zeleni wc.jpg',
        thumbnail: './assets/zeleni wc.jpg',
    },
   
];
 
class MyGallery1 extends React.Component {
  render() {
    return (
        <div className='slide'>
            <ImageGallery showPlayButton={false} autoPlay={true} showFullscreenButton={false} items={images} />
        </div>
    );
  }
}

export default MyGallery1;