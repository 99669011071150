import React from 'react'

import ImageGallery from 'react-image-gallery';

import '../slideshow.scss';
 
const images = [
    {
        original: './assets/0001.jpg',
        thumbnail: './assets/0001.jpg',
    },
    {
        original: './assets/0002.jpg',
        thumbnail: './assets/0002.jpg',
    },
    {
        original: './assets/0004.jpg',
        thumbnail: './assets/0004.jpg',
    },
    {
        original: './assets/0005.jpg',
        thumbnail: './assets/0005.jpg',
    },
    {
        original: './assets/0006.jpg',
        thumbnail: './assets/0006.jpg',
    },
    {
        original: './assets/0007.jpg',
        thumbnail: './assets/0007.jpg',
    },
    {
        original: './assets/0008.jpg',
        thumbnail: './assets/0008.jpg',
    },
    {
        original: './assets/0009.jpg',
        thumbnail: './assets/0009.jpg',
    },
    {
        original: './assets/0010.jpg',
        thumbnail: './assets/0010.jpg',
    },
    {
        original: './assets/0011.jpg',
        thumbnail: './assets/0011.jpg',
    },
    {
        original: './assets/0012.jpg',
        thumbnail: './assets/0012.jpg',
    },
    {
        original: './assets/0013.jpg',
        thumbnail: './assets/0013.jpg',
    },
    {
        original: './assets/0014.jpg',
        thumbnail: './assets/0014.jpg',
    },
    {
        original: './assets/0015.jpg',
        thumbnail: './assets/0015.jpg',
    },
    {
        original: './assets/0016.jpg',
        thumbnail: './assets/0016.jpg',
    },
    {
        original: './assets/0017.jpg',
        thumbnail: './assets/0017.jpg',
    },
    {
        original: './assets/brod galebovi plaza.jpg',
        thumbnail: './assets/brod galebovi plaza.jpg',
    },
    {
        original: './assets/brod mriza kuca.jpg',
        thumbnail: './assets/brod mriza kuca.jpg',
    },
    {
        original: './assets/brod plaza.jpg',
        thumbnail: './assets/brod plaza.jpg',
    },
];
 
class MyGallery extends React.Component {
  render() {
    return (
        <div className='slide'>
            <ImageGallery showPlayButton={false} autoPlay={true} showFullscreenButton={false} className='slide' items={images} />
        </div>
    );
  }
}

export default MyGallery;