import React, { Component } from 'react';

import './popup.scss';

import MyGallery31 from '../slideshow/slideshow-orka31';

//import GridImg from '../grid-img/grid-img'

class Popup31 extends Component{
    render() {
        return(
            <div className="popup" id="popup3-1">
                <div className="popup__content">
                    <a href="#apartments" class="popup__close">&times;</a>
                    <MyGallery31 />
                </div>
            </div>
        )
    }
};

export default Popup31;