import React from 'react'

import ImageGallery from 'react-image-gallery';

import '../slideshow.scss';
 
const images = [
    {
        original: './assets/stsoba.jpg',
        thumbnail: './assets/stsoba.jpg',
    },
    {
        original: './assets/stvece.jpg',
        thumbnail: './assets/stvece.jpg',
    },
    {
        original: './assets/stkuh.jpg',
        thumbnail: './assets/stkuh.jpg',
    },
    {
        original: './assets/stsoba3.jpg',
        thumbnail: './assets/stsoba3.jpg',
    }
];
 
class MyGallery1 extends React.Component {
  render() {
    return (
        <div className='slide'>
            <ImageGallery showPlayButton={false} autoPlay={true} showFullscreenButton={false} items={images} />
        </div>
    );
  }
}

export default MyGallery1;