import React from 'react'

import ImageGallery from 'react-image-gallery';

import './slideshow.scss';
 
const images = [
    {
        original: './assets/dnevni 3.jpg',
        thumbnail: './assets/dnevni 3.jpg',
    },
    {
        original: './assets/dnevni i sob.1.jpg',
        thumbnail: './assets/dnevni i sob.1.jpg',
    },
    {
        original: './assets/krevet djecji.jpg',
        thumbnail: './assets/krevet djecji.jpg',
    },
    {
        original: './assets/orka 5.jpg',
        thumbnail: './assets/orka 5.jpg',
    },
    {
        original: './assets/pogled dnevni b.jpg',
        thumbnail: './assets/pogled dnevni b.jpg',
    },
    {
        original: './assets/prizemlje ulaz i wc.jpg',
        thumbnail: './assets/prizemlje ulaz i wc.jpg',
    },
    {
        original: './assets/soba tv i balkon.jpg',
        thumbnail: './assets/soba tv i balkon.jpg',
    },
    {
        original: './assets/soba ulaz i wc.jpg',
        thumbnail: './assets/soba ulaz i wc.jpg',
    },
    {
        original: './assets/sobica.jpg',
        thumbnail: './assets/sobica.jpg',
    },
    {
        original: './assets/step dnevni.jpg',
        thumbnail: './assets/step dnevni.jpg',
    },
    {
        original: './assets/balkon iz sobe.jpg',
        thumbnail: './assets/balkon iz sobe.jpg',
    },
    {
        original: './assets/balkon 4.jpg',
        thumbnail: './assets/balkon 4.jpg',
    },
    {
        original: './assets/balkon(1).jpg',
        thumbnail: './assets/balkon(1).jpg',
    },
];
 
class MyGallery31 extends React.Component {
  render() {
    return (
        <div>
            <ImageGallery showPlayButton={false} autoPlay={true} showFullscreenButton={false} className='slide' items={images} />
        </div>
    );
  }
}

export default MyGallery31;