import React from 'react'

import ImageGallery from 'react-image-gallery';

import './slideshow.scss';
 
const images = [
    {
        original: './assets/ljub smanjena.jpg',
        thumbnail: './assets/ljub smanjena.jpg',
    },
    {
        original: './assets/ljub.fenjer lavanda.jpg',
        thumbnail: './assets/ljub.fenjer lavanda.jpg',
    },
    {
        original: './assets/ljub.pogled na sve.jpg',
        thumbnail: './assets/ljub.pogled na sve.jpg',
    },
    {
        original: './assets/ljub.wc.jpg',
        thumbnail: './assets/ljub.wc.jpg',
    },
    {
        original: './assets/ljub1.jpg',
        thumbnail: './assets/ljub1.jpg',
    },
    {
        original: './assets/ljubicasti soba.jpg',
        thumbnail: './assets/ljubicasti soba.jpg',
    },
    {
        original: './assets/ljun.balkon.jpg',
        thumbnail: './assets/ljun.balkon.jpg',
    },
];
 
class MyGallery2 extends React.Component {
  render() {
    return (
        <div className='slide'>
            <ImageGallery showPlayButton={false} autoPlay={true} showFullscreenButton={false} items={images} />
        </div>
    );
  }
}

export default MyGallery2;