import React from 'react'

import ImageGallery from 'react-image-gallery';

import '../slideshow.scss';
 
const images = [
    {
        original: './assets/soba2-sw.jpg',
        thumbnail: './assets/soba2-sw.jpg',
    },
    {
        original: './assets/swdnevni.jpg',
        thumbnail: './assets/swdnevni.jpg',
    },
    {
        original: './assets/soba-sw.jpg',
        thumbnail: './assets/soba-sw.jpg',
    },
    {
        original: './assets/dnevni-sw.jpg',
        thumbnail: './assets/dnevni-sw.jpg',
    },
    {
        original: './assets/swvece.jpg',
        thumbnail: './assets/swvece.jpg',
    },
    {
        original: './assets/brod plaza.jpg',
        thumbnail: './assets/brod plaza.jpg',
    }
];
 
class MyGallery2 extends React.Component {
  render() {
    return (
        <div className='slide'>
            <ImageGallery showPlayButton={false} autoPlay={true} showFullscreenButton={false} items={images} />
        </div>
    );
  }
}

export default MyGallery2;