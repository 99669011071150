import React, { Component } from 'react';

import '../popup.scss';

import MyGallery1 from '../../slideshow/slideshow-orka2';

//import GridImg from '../grid-img/grid-img'

class Popup1 extends Component{
    render() {
        return(
            <div className="popup" id="popup1">
                <div className="popup__content">
                    <a href="#apartments" class="popup__close">&times;</a>
                    <MyGallery1 />
                </div>
            </div>
        )
    }
};

export default Popup1;