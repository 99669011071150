import React from "react";

import "./App.css";

import HomePage from "./pages/home-page/home";

function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default App;
