import React, { Component } from "react";

import "../../App.css";
import "./home.scss";
import "./grid.css";

import "@fortawesome/fontawesome-free/css/all.css";

import Popup from "../../popup/orka1pop/popup";
import Popup1 from "../../popup/orka1pop/popup1";
import Popup2 from "../../popup/orka1pop/popup2";
import Popup3 from "../../popup/orka1pop/popup3";
import Popup21 from "../../popup/orka2pop/popup";
import Popup22 from "../../popup/orka2pop/popup1";
import Popup23 from "../../popup/orka2pop/popup2";
import Popup31 from "../../popup/orka3pop";

class HomePage extends Component {
  render() {
    return (
      <div>
        <Popup />
        <Popup1 />
        <Popup2 />
        <Popup3 />
        <Popup21 />
        <Popup22 />
        <Popup23 />
        <Popup31 />
        <body>
          <header id="home">
            <nav>
              <div className="row">
                <img className="logo" src='./assets/orka-logo.png' alt='apartments orka promajna logo' />
                <ul className="main-nav">
                  <li>
                    <a href="#features">Features</a>
                  </li>
                  <li>
                    <a href="#parasailing_offer">Special offer</a>
                  </li>
                  <li>
                    <a href="#apartments">Our apartments</a>
                  </li>
                  <li>
                    <a href="#contact_us">Contact us</a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="hero-text-box">
              <h1 className="heading-primary-main">apartments orka promajna</h1>
              <h1 className="heading-primary-sub">BEACHFRONT APARTMENTS</h1>
              <a className="btn btn-full" href="#contact_us">
                Book Now!
              </a>
              <a className="btn btn-ghost" href="#apartments">
                Show me options
              </a>
            </div>
          </header>

          <section className="section-features" id="features">
            <div className="row">
              <h2>apartments orka promajna &mdash; THE PERFECT CHOICE FOR YOU</h2>
              <p className="long-copy">
              Apartments Orka Promajna are located in a quiet surrounding right next 
              to a pebble beach in the small fishing village of Promajna. 
              Apartments Orka provide air-conditioned accommodation with free 
              WiFiaccess and a parking space. A balcony or terrace is available 
              in each unit.
              </p>
            </div>

            <div className="row ">
              <div className="col span-1-of-4 box">
                <div className="feature-box">
                  <i className="feature-box__icon icon-basic-home"></i>
                  <h3 className="heading-tertiary u-center-text">apartments</h3>
                  <p className="feature-box__text ">
                    We offer beautiful beachfront apartments.
                  </p>
                </div>
              </div>
              <div className="col span-1-of-4 box">
                <div className="feature-box">
                  <i className="feature-box__icon icon-basic-rss"></i>
                  <h3 className="heading-tertiary u-center-text">Free wi-fi</h3>
                  <p className="feature-box__text ">
                    We include fast and free internet connection.
                  </p>
                </div>
              </div>
              <div className="col span-1-of-4 box">
                <div className="feature-box">
                  <i className="feature-box__icon icon-basic-clockwise"></i>
                  <h3 className="heading-tertiary u-center-text">Parking</h3>
                  <p className="feature-box__text ">
                    We include a free 24h parking space.
                  </p>
                </div>
              </div>
              <div className="col span-1-of-4 box">
                <div className="feature-box">
                  <i className="feature-box__icon icon-basic-geolocalize-05"></i>
                  <h3 className="heading-tertiary u-center-text">Beachfront</h3>
                  <p className="feature-box__text ">
                    Our apartments are located next to the beach
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="photos">
            <ul className="meals-showcase clearfix">
              <li>
                <figure className="apartment-photo">
                  <img src="./assets/balkon3.jpg" alt='apartments orka balcony'/>
                </figure>
              </li>
              <li>
                <figure className="apartment-photo">
                  <img src="./assets/soba3.jpg" alt='apartments orka room'/>
                </figure>
              </li>
              <li>
                <figure className="apartment-photo">
                  <img src="./assets/ulaz.jpg" alt='apartments orka enterance'/>
                </figure>
              </li>
              <li>
                <figure className="apartment-photo">
                  <img src="./assets/dnevni.jpg" alt='apartments orka livingroom'/>
                </figure>
              </li>
            </ul>
            <ul className="meals-showcase clearfix">
              <li>
                <figure className="apartment-photo">
                  <img src="./assets/pogled.jpg" alt='apartments orka view'/>
                </figure>
              </li>
              <li>
                <figure className="apartment-photo">
                  <img src="./assets/brod plaza.jpg" alt='apartments orka boat'/>
                </figure>
              </li>
              <li>
                <figure className="apartment-photo">
                  <img src="./assets/balkon2.jpg" alt='apartments orka balcony'/>
                </figure>
              </li>
              <li>
                <figure className="apartment-photo">
                  <img src="./assets/soba2.jpg" alt='apartments orka room'/>
                </figure>
              </li>
            </ul>
          </section>

          <section className="section-steps" id="parasailing_offer">
            <div className="row">
              <h2>Parasailing &mdash; water sports</h2>
              <p className="long-copy">
                We also offer recreational activities like parasailing, jet
                Skiing, tube riding, and even more! We also rent boats for those
                of you who had a more peaceful holiday in mind.
              </p>
            </div>
            <div className="row">
              <div className="col span-1-of-2 steps-box">
                <img
                  src="./assets/parasailing2.jpg"
                  alt="parasailig"
                  className="parasailing"
                />
              </div>
              <div className="col span-1-of-2 steps-box">
                <div className="works-step clearfix">
                  <div>1</div>
                  <p>Choose your discipline.</p>
                </div>
                <div className="works-step clearfix">
                  <div>2</div>
                  <p>
                    Physically order it. Or call us:
                    <br />
                    <a className="broj" href="tel:+385 98 3211 52">
                      {" "}
                      +385 98 3211 52.
                    </a>
                  </p>
                </div>
                <div className="works-step clearfix">
                  <div>3</div>
                  <p>Enjoy your ride!</p>
                </div>
              </div>
            </div>
          </section>

          <section className="section-apartments" id="apartments">
            <div className="row">
              <h2>Apartments orka promajna</h2>
            </div>
            <div className="row js--wp-3">
              <a className="linka" href="#popup">
                <div className="col span-1-of-4 box">
                  <div className="apartmani">
                    <img src="./assets/balkon3.jpg" alt='apartments orka balcony' />
                    <h3 className="apart-orka1">
                      Studio with Balcony and Sea View
                    </h3>
                    <div className="apartment-feature">
                      <i
                        className="fa fa-user-times person"
                        aria-hidden="true"
                      ></i>
                      2 person
                    </div>
                    <div className="apartment-feature">
                      <i className="icon-basic-mail person"></i>
                      <a
                        className="email navlink"
                        href="mailto:ipivac74@gmail.com"
                      >
                        ipivac74@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a href="#popup1">
                <div className="col span-1-of-4 box">
                  <div className="apartmani">
                    <img src="./assets/zeleni balkon1.jpg" alt='apartments orka balcony'/>
                    <h3 className="apart-orka1">
                      Studio with Balcony and Sea View
                    </h3>
                    <div className="apartment-feature">
                      <i
                        className="fa fa-user-times person"
                        aria-hidden="true"
                      ></i>
                      2 person
                    </div>
                    <div className="apartment-feature">
                      <i className="icon-basic-mail person"></i>
                      <a
                        className="email navlink"
                        href="mailto:ipivac74@gmail.com"
                      >
                        ipivac74@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a href="#popup2">
                <div className="col span-1-of-4 box">
                  <div className="apartmani">
                    <img src='./assets/ljub.fenjer lavanda.jpg' alt='apartments orka balcony'/>
                    <h3 className="apart-orka1">
                      Studio with balcony and backyard view
                    </h3>
                    <div className="apartment-feature">
                      <i
                        className="fa fa-user-times person"
                        aria-hidden="true"
                      ></i>
                      2 person
                    </div>
                    <div className="apartment-feature">
                      <i className="icon-basic-mail person"></i>
                      <a
                        className="email navlink"
                        href="mailto:ipivac74@gmail.com"
                      >
                        ipivac74@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a href="#popup3">
                <div className="col span-1-of-4 box">
                  <div className="apartmani">
                    <img src='./assets/priz.soba i ormar.jpg' alt='apartments orka promajna'/>
                    <h3 className="apart-orka1">
                      One-Bedroom Apartment with Terrace and Sea View
                    </h3>
                    <div className="apartment-feature">
                      <i
                        className="fa fa-user-times person"
                        aria-hidden="true"
                      ></i>
                      4 person
                    </div>
                    <div className="apartment-feature">
                      <i className="icon-basic-mail person"></i>
                      <a
                        className="email navlink"
                        href="mailto:ipivac74@gmail.com"
                      >
                        ipivac74@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </section>

          <section className="section-apartments">
            <div className="row">
              <h2>Apartments orka 2 promajna</h2>
            </div>
            <div className="row js--wp-3">
              <a className="linka" href="#popup2-1">
                <div className="col span-1-of-3 box">
                  <div className="apartmani">
                    <img src='./assets/0002.jpg' alt='apartments orka promajna'/>
                    <h3 className="apart-orka1">Apartment</h3>
                    <div className="apartment-feature">
                      <i
                        className="fa fa-user-times person"
                        aria-hidden="true"
                      ></i>
                      5 person
                    </div>
                    <div className="apartment-feature">
                      <i className="icon-basic-mail person"></i>
                      <a
                        className="email navlink"
                        href="mailto:ipivac74@gmail.com"
                      >
                        ipivac74@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a href="#popup2-2">
                <div className="col span-1-of-3 box">
                  <div className="apartmani">
                    <img src='./assets/stsoba.jpg' alt='apartments orka balcony'/>
                    <h3 className="apart-orka1">Standard Studio</h3>
                    <div className="apartment-feature">
                      <i
                        className="fa fa-user-times person"
                        aria-hidden="true"
                      ></i>
                      2 person
                    </div>
                    <div className="apartment-feature">
                      <i className="icon-basic-mail person"></i>
                      <a
                        className="email navlink"
                        href="mailto:ipivac74@gmail.com"
                      >
                        ipivac74@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a href="#popup2-3">
                <div className="col span-1-of-3 box">
                  <div className="apartmani">
                    <img src="./assets/soba-sw.jpg" alt='apartments orka balcony'/>
                    <h3 className="apart-orka1">Apartment with Sea View</h3>
                    <div className="apartment-feature">
                      <i
                        className="fa fa-user-times person"
                        aria-hidden="true"
                      ></i>
                      4 person
                    </div>
                    <div className="apartment-feature">
                      <i className="icon-basic-mail person"></i>
                      <a
                        className="email navlink"
                        href="mailto:ipivac74@gmail.com"
                      >
                        ipivac74@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </section>
          <section className="section-apartments" id="apartments">
            <div className="row">
              <h2>Holiday home orka 5</h2>
            </div>
            <div className="row-orka3">
              <a className="linka" href="#popup3-1">
                <div className="span-2-of-2 box">
                  <div className="apartmani">
                    <img src="./assets/balkonhh2.jpg" alt='apartments orka balcony'/>
                    <h3>Holiday Home</h3>
                    <div className="apartment-feature">
                      <i
                        className="fa fa-user-times person"
                        aria-hidden="true"
                      ></i>
                      5 person
                    </div>
                    <div className="apartment-feature">
                      <i className="icon-basic-mail person"></i>
                      <a
                        className="email navlink"
                        href="mailto:ipivac74@gmail.com"
                      >
                        ipivac74@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </section>

          <section className="section-testimonials">
            <div className="row">
              <h2>Our guests loved it</h2>
            </div>
            <div className="row">
              <div className="col span-1-of-3">
                <blockquote>
                  Excellent location (30 metres from the beach, 100 metres from
                  the centre of Promajna), very clean and tidy rooms, and very
                  friendly hosts. If we visit Promajna, we're staying here
                  again.
                </blockquote>
              </div>
              <div className="col span-1-of-3">
                <blockquote>
                  Nice and very helpful and helpful owners, apartment very
                  spacious with a large terrace (2 awnings available), a large
                  bathroom (with washing machine) and plenty of storage space.
                </blockquote>
              </div>
              <div className="col span-1-of-3">
                <blockquote>
                  Strong and trouble-free wifi signal - you could watch Skylink
                  TV over the Internet from my laptop and we connected to their
                  big TV with the HDMI cable I had with me. Another advantage is
                  the proximity of a good restaurant Hajduk.
                </blockquote>
              </div>
            </div>
          </section>

          {/*
                    <section className="section-contact_us js--section-contact_us" id="contact_us">
                        <div className="row">
                            <h2>Start eating healthy today</h2>
                        </div>
                        <div className="row">
                            <div className="col span-1-of-3">
                                <div className="plan-box js--wp-4">
                                    <div>
                                        <h3>Premium</h3>
                                        <p className="plan-price">$399 <span>/ month</span></p>
                                        <p className="plan-price-meal">That's only 13.30$ per meal</p>
                                    </div>
                                    <div>
                                        <ul>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>1 meal every day</li>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>Order 24/7</li>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>Access to newest creations</li>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>Free delivery</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <a  className="btn btn-full">Sign up now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col span-1-of-3">
                                <div className="plan-box">
                                    <div>
                                        <h3>Pro</h3>
                                        <p className="plan-price">$149 <span>/ month</span></p>
                                        <p className="plan-price-meal">That's only 14.90$ per meal</p>
                                    </div>
                                    <div>
                                        <ul>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>1 meal 10 days/month</li>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>Order 24/7</li>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>Access to newest creations</li>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>Free delivery</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <a href="#" className="btn btn-ghost">Sign up now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col span-1-of-3">
                                <div className="plan-box">
                                    <div>
                                        <h3>Starter</h3>
                                        <p className="plan-price">19$ <span>/ meal</span></p>
                                        <p className="plan-price-meal">&nbsp;</p>
                                    </div>
                                    <div>
                                        <ul>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>1 meal</li>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>Order from 8 am to 12 pm</li>
                                            <li><i className="ion-ios-close-empty icon-small"></i></li>
                                            <li><i className="ion-ios-checkmark-empty icon-small"></i>Free delivery</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <a href="#" className="btn btn-ghost">Sign up now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>*/}

          <section className="section-apartments" id="contact_us">
            <div className="row">
              <h2>Contact us to make a reservation</h2>
            </div>
            <div className="row js--wp-3">
              <div className="col span-1-of-3 box">
                <a href="tel:+385 91 224 4910">
                  <div className="feature-box">
                    <i className="feature-box__icon icon-basic-smartphone"></i>
                    <h3 className="heading-tertiary u-center-text">PHONE</h3>
                    <p className="feature-box__text ">
                      Please fill free to contact us over phone at the following
                      number: +385 91 224 4910
                    </p>
                  </div>
                </a>
              </div>
              <div className="col span-1-of-3 box">
                <a href="mailto:ipivac74@gmail.com">
                  <div className="feature-box">
                    <i className="feature-box__icon icon-basic-mail"></i>
                    <h3 className="heading-tertiary u-center-text">EMAIL</h3>
                    <p className="feature-box__text ">
                      Please fill free to email us your questions and requests:
                      ipivac74@gmail.com
                    </p>
                  </div>
                </a>
              </div>
              <div className="col span-1-of-3 box">
                <div className="feature-box">
                  <i className="feature-box__icon icon-basic-geolocalize-01"></i>
                  <h3 className="heading-tertiary u-center-text">LOCATION</h3>
                  <p className="feature-box__text">
                    You can find us at the following location: Braće Ivandić 2,
                    Promajna and Obala 46 Promajna.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <div className="row">
              <ul className="footer-nav">
                <li>
                  <a className="footer__item footer__link" href="#home">
                    Home
                  </a>
                </li>
                <li>
                  <a className="footer__item footer__link" href="#features">
                    Features
                  </a>
                </li>
                <li>
                  <a
                    className="footer__item footer__link"
                    href="#parasailing_offer"
                  >
                    Special offer
                  </a>
                </li>
                <li>
                  <a className="footer__item footer__link" href="#apartments">
                    Our apartments
                  </a>
                </li>
                <li>
                  <a className="footer__item footer__link" href="#contact_us">
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div className="row">
              <p>
                Built in 2020 by{" "}
                <a className="footer__link" href="http://code-drip.com/">
                  Code Drip
                </a>
                . Copyright &copy; by Code Drip.
              </p>
            </div>
          </footer>
        </body>
      </div>
    );
  }
}

export default HomePage;
