import React, { Component } from 'react';

import '../popup.scss';

import MyGallery from '../../slideshow/orka2/slideshow-orka';

//import GridImg from '../grid-img/grid-img'

class Popup21 extends Component{
    render() {
        return(
            <div className="popup" id="popup2-1">
                <div className="popup__content">
                    <a href="#apartments" class="popup__close">&times;</a>
                    <MyGallery />
                </div>
            </div>
        )
    }
};

export default Popup21;