import React from 'react'

import ImageGallery from 'react-image-gallery';

import './slideshow.scss';
 
const images = [
    {
        original: './assets/plavi-krevet.png',
        thumbnail: './assets/plavi-krevet.png',
    },
    {
        original: './assets/plavi balkon wow.jpg',
        thumbnail: './assets/plavi balkon wow.jpg',
    },
    {
        original: './assets/plavi kuhinja i soba.jpg',
        thumbnail: './assets/plavi kuhinja i soba.jpg',
    },
    {
        original: './assets/plavi kuhinja.jpg',
        thumbnail: './assets/plavi kuhinja.jpg',
    },
    {
        original: './assets/plavi soba slika.jpg',
        thumbnail: './assets/plavi soba slika.jpg',
    },
    {
        original: './assets/plavi2.jpg',
        thumbnail: './assets/plavi2.jpg',
    },
    {
        original: './assets/priz-wc.jpg',
        thumbnail: './assets/priz-wc.jpg',
    },
];
 
class MyGallery extends React.Component {
  render() {
    return (
        <div>
            <ImageGallery showPlayButton={false} autoPlay={true} showFullscreenButton={false} className='slide' items={images} />
        </div>
    );
  }
}

export default MyGallery;