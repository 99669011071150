import React from 'react'

import ImageGallery from 'react-image-gallery';

import './slideshow.scss';
 
const images = [
    {
        original: './assets/priz. tv stol.jpg',
        thumbnail: './assets/priz. tv stol.jpg',
    },
    {
        original: './assets/priz.kuhinja.jpg',
        thumbnail: './assets/priz.kuhinja.jpg',
    },
    {
        original: './assets/priz.soba i ormar.jpg',
        thumbnail: './assets/priz.soba i ormar.jpg',
    },
    {
        original: './assets/priz.terasa.jpg',
        thumbnail: './assets/priz.terasa.jpg',
    },
    {
        original: './assets/priz.ulaz terasa wow.jpg',
        thumbnail: './assets/priz.ulaz terasa wow.jpg',
    },
    {
        original: './assets/priz.wc.jpg',
        thumbnail: './assets/priz.wc.jpg',
    },
    {
        original: './assets/priz.wc1.jpg',
        thumbnail: './assets/priz.wc1.jpg',
    },
    {
        original: './assets/prizemlje sve.jpg',
        thumbnail: './assets/prizemlje sve.jpg',
    },
    {
        original: './assets/Promajna.jpg',
        thumbnail: './assets/Promajna.jpg',
    },
    {
        original: './assets/promajna1.jpg',
        thumbnail: './assets/promajna1.jpg',
    },
];
 
class MyGallery3 extends React.Component {
  render() {
    return (
        <div className='slide'>
            <ImageGallery showPlayButton={false} autoPlay={true} showFullscreenButton={false} className='slide' items={images} />
        </div>
    );
  }
}

export default MyGallery3;